:root {
  --index: 0;
}

.container-style {
  position: absolute;
  bottom: 20px;
  transition: left 0.5s ease-in-out;
}

.left-button, .right-button {
  position: absolute;
  bottom: 10px;
  height: 120px;
  width: 10vw;
  color: transparent;
  cursor: pointer;
  text-align: center;
  padding-top: 10px;
}

.left-button {
  left: 0;
}

.right-button {
  right: 0;
}

.badge-style {
  color: var(--ion-color-primary);
  background-color: var(--ion-color-primary-contrast);
  border-radius: 30px;
  text-align: center;
  width: 80vw;
  height: 100px;
  margin: 0 10px;
  position: absolute;
  bottom: 0;
  /*left: calc(var(--index)*(80vw + 20px));*/
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.content-style {
  overflow: visible;
  --overflow: visible;
}
